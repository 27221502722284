@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-company-detail-item {
  display: flex;
  align-items: center;
  flex: 1 0 100%;
  justify-content: flex-start;
  gap: 0.5rem;
  flex-wrap: nowrap;
  color: var(--ac-color-primary);
  fill: var(--ac-color-primary);

  @media #{$breakpoint-md-up} {
    flex: 0 0 auto;
  }

  &-wrapper {
    display: flex;
    flex-direction: row;
    flex: 1 0 100%;
    flex-wrap: wrap;
    justify-content: flex-start;
    align-items: center;
    padding-bottom: 1rem;

    @media #{$breakpoint-md-up} {
      align-items: unset;
      flex-direction: column;
      padding-bottom: 1.5rem;
    }
  }
}

.ac-company-detail-error {
  display: flex;
  align-items: center;
  flex: 1 0 100%;
  justify-content: center;
  flex-direction: column;
  gap: 0.5rem;
  flex-wrap: nowrap;
  color: var(--ac-color-primary);
  fill: var(--ac-color-primary);
}
