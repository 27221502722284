@import '../../styles/globals/typography';
@import '../../styles/globals/breakpoints';
@import '../../styles/globals/mixins';
@import '../../styles/globals/variables';
@import '../../styles/globals/grid';
@import '../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-data-explorer-about {
  &--container {
    padding: 3rem 1rem 0;
    max-width: 603px;
    margin: 0 auto;
  }

  &-back-button {
    margin-bottom: 1rem;
  }
}
