@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-chart {
  &-wrapper {
    background: linear-gradient(
      180deg,
      #ffffff99 0%,
      rgba(255, 255, 255, 0) 104.38%
    );
    border-radius: 1001px 1000px 0 0;
    position: relative;
    height: 0;
    padding-bottom: 50%;
    width: 100%;
    box-shadow: 0px -32px 32px rgba(193, 200, 209, 0.4);
    max-width: 37.5rem;
    margin: 3.5rem auto;
    position: relative;

    @media #{$breakpoint-md-up} {
      padding-bottom: 300px;
    }

    & > div::after {
      content: var(--current-year-pie-chart);
      display: flex;
      background: #f9f7fc;
      border-radius: 50%;
      height: 22.5%;
      width: 22.5%;
      align-items: center;
      justify-content: center;
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);

      @include title-font();
      font-size: 2rem;
      color: var(--ac-color-primary);
      box-shadow: 0px -4px 12px rgba(68, 44, 44, 0.07);

      @media #{$breakpoint-md-up} {
        height: 9rem;
        width: 9rem;
      }
    }
  }
}
