@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-button {
  position: relative;
  border: var(--ac-pixel-size-1) solid var(--ac-color-transparent);
  cursor: pointer;
  display: inline-block;
  vertical-align: bottom;
  @include button-transitions();

  &-inner {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }

  &-tooltip {
    position: absolute;
    display: inline-block;
    background-color: var(--ac-color-neutral-900);
    padding: var(--ac-pixel-size-8);
    border-radius: var(--ac-pixel-size-7);
    top: calc(100% + var(--ac-pixel-size-8));
    left: 0;
    right: 0;
    margin: 0 auto;
    width: max-content;
    color: var(--ac-color-neutral-white);
    @include title-font();
    @include font-size(var(--ac-pixel-size-8));
  }

  // DEFAULT VARIANT

  &-variant-default {
    border-radius: var(--ac-pixel-size-9);

    // DEFAULT PRIMARY

    &-color-primary {
      background-color: var(--ac-color-primary);
      color: var(--ac-color-neutral-white);
      fill: var(--ac-color-neutral-white);

      // &:hover {
      //   background-color: var(--ac-color-primary-700);
      // }

      // &:focus-visible {
      //   outline: var(--ac-pixel-size-4) solid var(--ac-color-primary-300);
      // }
    }

    // DEFAULT SECONDARY

    &-color-secondary {
      background-color: var(--ac-color-neutral-white);
      // border-color: var(--ac-color-neutral-500);
      color: var(--ac-color-primary);
      fill: var(--ac-color-primary);

      &:hover {
        color: var(--ac-color-neutral-700);
        fill: var(--ac-color-neutral-700);
        background-color: var(--ac-color-secondary-100);
      }

      &:focus-visible {
        outline: var(--ac-pixel-size-4) solid var(--ac-color-secondary-100);
      }
    }

    // DEFAULT WITH ICON

    &--has-icon {
      padding: var(--ac-pixel-size-14) var(--ac-pixel-size-24)
        var(--ac-pixel-size-14) var(--ac-pixel-size-16);

      .ac-button-icon {
        margin-right: var(--ac-pixel-size-10);
      }
    }

    // DEFAULT DISABLED

    &--disabled {
      background-color: var(--ac-color-neutral-400);
      border-color: var(--ac-color-neutral-400);
      pointer-events: none;
      color: var(--ac-color-neutral-200);
      fill: var(--ac-color-neutral-200);
    }
  }

  // TEXT VARIANT

  &-variant-text {
    border: 0;
    background-color: var(--ac-color-transparent);

    // TEXT PRIMARY

    &-color-primary {
      color: var(--ac-color-primary-500);
      fill: var(--ac-color-primary-500);

      &:hover {
        color: var(--ac-color-primary-700);
        fill: var(--ac-color-primary-700);
      }
    }

    // TEXT WITH ICON

    &--has-icon {
      .ac-button-icon {
        margin-right: var(--ac-pixel-size-10);
      }
    }

    // TEXT DISABLED

    &--disabled {
      pointer-events: none;
      color: var(--ac-color-neutral-400);
      fill: var(--ac-color-neutral-400);
    }
  }

  // LINK VARIANT

  &-variant-link {
    padding: 0;
    border: 0;
    background-color: var(--ac-color-transparent);

    // LINK PRIMARY

    &-color-primary {
      color: var(--ac-color-primary-500);
      fill: var(--ac-color-primary-500);

      &:hover {
        text-decoration: underline;
      }
    }

    // // TEXT WITH ICON

    // &--has-icon {
    //   .ac-button-icon {
    //     margin-right: var(--ac-pixel-size-10);
    //   }
    // }

    // TEXT DISABLED

    .ac-button-label {
      font-weight: var(--ac-typography-font-weight-regular);
    }

    &--disabled {
      pointer-events: none;
      color: var(--ac-color-neutral-400);
      fill: var(--ac-color-neutral-400);
    }
  }

  // ICON VARIANT

  &-variant-icon {
    padding: 0.75rem 1.25rem;
    border-radius: 50%;
    background-color: var(--ac-color-primary);
    fill: var(--ac-color-neutral-white);
    max-width: 4rem;
    display: flex;
    align-items: center;
    justify-content: center;

    // ICON PRIMARY

    &-color-primary {
      &:hover,
      &:focus {
        background-color: rgba(249, 131, 76, 0.08);
        fill: var(--ac-color-primary-500);
      }

      &:focus-visible {
        outline: none;
      }
    }

    // ICON SECONDARY

    &-color-secondary {
      background-color: var(--ac-color-neutral-white);
      color: var(--ac-color-primary);
      fill: var(--ac-color-primary);
      &:hover,
      &:focus-visible {
        background-color: var(--ac-color-primary);
        color: var(--ac-color-neutral-white);
        fill: var(--ac-color-neutral-white);
      }

      &:active {
        background-color: rgba(77, 102, 99, 0.16);
      }

      &:focus-visible {
        outline: none;
      }
    }

    // ICON DISABLED

    &--disabled {
      pointer-events: none;
      color: var(--ac-color-neutral-400);
      fill: var(--ac-color-neutral-400);
    }

    .ac-button-icon {
      width: 1.5rem;
      height: 1.5rem;
      max-width: 1.5rem;
      max-height: 1.5rem;
    }
  }

  // PUSH VARIANT

  &-variant-push {
    border-radius: var(--ac-pixel-size-9);

    // PUSH PRIMARY

    &-color-primary {
      background-color: var(--ac-color-primary-500);
      color: var(--ac-color-neutral-white);
      fill: var(--ac-color-neutral-white);

      &:hover {
        background-color: var(--ac-color-primary-700);
        box-shadow: var(--ac-box-shadow-md);
      }

      &:focus-visible {
        outline: var(--ac-pixel-size-4) solid var(--ac-color-primary-300);
      }
    }

    // PUSH SECONDARY

    &-color-secondary {
      background-color: var(--ac-color-neutral-white);
      color: var(--ac-color-neutral-700);
      fill: var(--ac-color-neutral-700);

      &:hover {
        box-shadow: var(--ac-box-shadow-md);
        color: var(--ac-color-neutral-900);
        fill: var(--ac-color-neutral-900);
      }

      &:focus-visible {
        outline: var(--ac-pixel-size-4) solid var(--ac-color-secondary-100);
      }
    }

    // PUSH WITH ICON

    &--has-icon {
      padding: var(--ac-pixel-size-14) var(--ac-pixel-size-24)
        var(--ac-pixel-size-14) var(--ac-pixel-size-16);

      .ac-button-icon {
        margin-right: var(--ac-pixel-size-10);
      }
    }

    // PUSH DISABLED

    &--disabled {
      background-color: var(--ac-color-neutral-400);
      border-color: var(--ac-color-neutral-400);
      pointer-events: none;
      color: var(--ac-color-neutral-200);
      fill: var(--ac-color-neutral-200);
    }
  }
  // PILL VARIANT

  &-variant-pill {
    @extend .ac-button-variant-default;
    border-radius: 3rem;

    // PILL PRIMARY

    &-color-primary {
      @extend .ac-button-variant-default-color-primary;
    }

    // PILL SECONDARY

    &-color-secondary {
      @extend .ac-button-variant-default-color-secondary;
    }

    // PILL WITH ICON

    &--has-icon {
      @extend .ac-button-variant-default--has-icon;
    }

    // PILL DISABLED
    &--disabled {
      color: lightgray;
      &:hover {
        color: lightgray;
        cursor: auto;
      }
    }
  }

  &--full-width {
    width: 100%;
  }

  &--padding-none {
    padding: 0;
  }

  &--padding-xs {
    padding: var(--ac-pixel-size-4);
  }

  &--padding-default {
    padding: var(--ac-pixel-size-14) var(--ac-pixel-size-16);
  }

  &--padding-chip {
    padding: 0.375rem 0.75rem;
  }

  &--padding-md {
    padding: 0.75rem;
  }
}
