@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-shimmer {
  display: block;
  position: relative;
  width: 100%;
  min-height: 1.5rem;
  &:after {
    content: ' ';
    opacity: 0.5;
    width: 100%;
    height: 100%;
    min-height: 1.125rem;
    position: absolute;
    left: 0;
    background-image: linear-gradient(
      rgba(255, 255, 255, 0) 0%,
      rgba(255, 255, 255, 0) 100%
    );
    background: var(--ac-color-neutral-white);
    background-image: linear-gradient(
      to right,
      var(--ac-color-neutral-white) 0%,
      var(--ac-color-neutral-divider) 30%,
      var(--ac-color-neutral-white) 40%
    );
    background-repeat: no-repeat;
    background-size: 50rem 6.5rem;
    transition: visibility 0.2s, opacity 0.2s ease, background-image 0.2s,
      background-color 0.2s;

    animation: shimmer 1.5s forwards linear infinite;
  }
}

@keyframes shimmer {
  0% {
    background-position: -25rem 0;
  }
  100% {
    background-position: 125rem 0;
  }
}
