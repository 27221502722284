:root {
  /* ---- Typography ---- */

  /* Font-size */
  --ac-typography-font-size-48: 3rem;
  --ac-typography-font-size-32: 2rem;
  --ac-typography-font-size-26: 1.625rem;
  --ac-typography-font-size-24: 1.5rem;
  --ac-typography-font-size-20: 1.25rem;
  --ac-typography-font-size-18: 1.125rem;
  --ac-typography-font-size-16: 1rem;
  --ac-typography-font-size-14: 0.875rem;
  --ac-typography-font-size-12: 0.75rem;

  /* Line-height */

  --ac-typography-line-height-120: 1.2;
  --ac-typography-line-height-58: 58px;
  --ac-typography-line-height-38: 38px;
  --ac-typography-line-height-31: 31px;
  --ac-typography-line-height-24: 24px;
  --ac-typography-line-height-22: 22px;
  --ac-typography-line-height-20: 20px;
  --ac-typography-line-height-19: 19px;

  /* Font-weight */
  --ac-typography-font-weight-bold: 700;
  --ac-typography-font-weight-regular: 400;
  --ac-typography-font-weight-light: 300;

  /* ---- Color ---- */

  /* Primary */
  @include define-color("--ac-color-primary", #241d6d);

  /* Secondary */
  @include define-color("--ac-color-secondary-fuchia", #97047e);
  @include define-color("--ac-color-secondary-orange", #d89427);
  @include define-color("--ac-color-secondary-green", #999e19);
  @include define-color("--ac-color-secondary-lightblue2", #54b4e4);
  @include define-color("--ac-color-secondary-lightblue3", #d3e7f4);

  /* Neutral */
  @include define-color("--ac-color-neutral-white", #ffffff);
  @include define-color("--ac-color-neutral-background", #f2f3f7);
  @include define-color("--ac-color-neutral-grey", #b9b9ba);
  @include define-color("--ac-color-neutral-divider", #ebebeb);
  @include define-color("--ac-color-neutral-lightgrey", #dadada);
  @include define-color("--ac-color-neutral-header", #37474f);
  @include define-color("--ac-color-neutral-black", #000000);

  /* Accent */
  @include define-color("--ac-color-accent-blue-100", #a7a5c5);
  @include define-color("--ac-color-accent-blue-200", #7c77a7);
  @include define-color("--ac-color-accent-green-100", #c4e2aa);
  @include define-color("--ac-color-accent-green-200", #89c758);
  @include define-color("--ac-color-accent-green-300", #68ad31);

  /* Background */
  @include define-color("--ac-app-background-color", #f2f3f7);

  /* Transparent */
  --ac-color-transparent: rgba(0, 0, 0, 0);

  /* ---- Shadows ---- */
  --ac-box-shadow-md: 0px 4px 24px rgba(178, 183, 219, 0.25), 0px 10px 28px rgba(178, 183, 219, 0.11);
  --ac-box-shadow-md-2: 0px 4px 17px rgba(178, 183, 219, 0.06), 0px 4px 36px rgba(178, 183, 219, 0.25);

  --ac-box-shadow-button: 0px 1px 8px rgba(178, 183, 219, 0.64), 0px 10px 28px rgba(178, 183, 219, 0.11);

  /* ---- Sizes ---- */
  --ac-pixel-size-1: 0.0625rem;
  --ac-pixel-size-2: 0.125rem;
  --ac-pixel-size-4: 0.25rem;
  --ac-pixel-size-6: 0.375rem;
  --ac-pixel-size-7: 0.4375rem;
  --ac-pixel-size-8: 0.5rem;
  --ac-pixel-size-9: 0.5625rem;
  --ac-pixel-size-10: 0.625rem;
  --ac-pixel-size-12: 0.75rem;
  --ac-pixel-size-14: 0.875rem;
  --ac-pixel-size-16: 1rem;
  --ac-pixel-size-20: 1.25rem;
  --ac-pixel-size-21: 21px;
  --ac-pixel-size-24: 1.5rem;
  --ac-pixel-size-32: 2rem;
  --ac-pixel-size-40: 2.5rem;
  --ac-pixel-size-48: 3rem;
  --ac-pixel-size-60: 3.75rem;
  --ac-pixel-size-99: 6.1875rem;

  --ac-mobile-menu-height: var(--ac-pixel-size-60);
}
