@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-app-header {
  position: sticky;
  z-index: 99;
  top: 0;
  display: flex;
  flex-direction: row;
  padding: 1rem;
  min-height: var(--ac-mobile-menu-height);
  align-items: center;
  background-color: var(--ac-color-neutral-white);
  border-bottom: 1px solid rgba(36, 29, 109, 0.12);

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: var(--ac-box-shadow-md-2);
    z-index: -1;
  }

  @media #{$breakpoint-md-up} {
    position: relative;
    z-index: 1;
    padding: 1.75rem 2.5rem;
    height: 5rem;
  }

  &-left {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &-right {
    display: flex;
    flex: 1 0 auto;
    flex-direction: row;
    align-items: center;
    justify-content: flex-end;
    gap: 1.5rem;
  }

  &-logo {
    max-height: 1rem;
    width: auto;

    @media #{$breakpoint-md-up} {
      max-height: 1.5rem;
    }
  }

  &-menu-button {
    width: 24px;
    height: 24px;
    position: relative;
    overflow: hidden;

    &:hover {
      cursor: pointer;
    }

    &-line {
      width: 24px;
      height: 1px;
      background-color: var(--ac-color-primary);
      display: block;
      position: absolute;
      transition: all 0.25s ease;

      &:first-of-type {
        top: 4px;
      }

      &:nth-child(2) {
        top: 12px;
        right: 0;
      }

      &:last-of-type {
        top: 20px;
      }
    }

    &--open {
      .ac-app-header-menu-button-line {
        &:first-of-type {
          transform: rotate(135deg);
          top: 8px;
        }
        &:nth-child(2) {
          right: 100%;
          opacity: 0;
        }

        &:last-of-type {
          transform: rotate(-135deg);
          top: 8px;
        }
      }
    }
  }

  &-mobile-menu {
    position: fixed;
    width: 100vw;
    top: var(--ac-mobile-menu-height);
    right: 0;
    left: 100%;
    bottom: 0;
    background: var(--ac-color-neutral-white);
    z-index: 999;
    padding: 1rem;
    transition: left 0.25s ease;

    &-notice {
      background-color: var(--ac-color-secondary-lightblue3);
      padding: 1rem;
    }

    &--visible {
      left: 0;
    }
  }

  &-navlink {
    display: flex;
    font-weight: var(--ac-typography-font-weight-regular);
    align-items: center;
    gap: 0.5rem;
    flex-direction: row;
    color: var(--ac-color-primary);
    fill: var(--ac-color-primary);

    &:hover {
      text-decoration: underline;
    }

    &--disabled {
      pointer-events: none;
      color: var(--ac-color-neutral-grey);
      fill: var(--ac-color-neutral-grey);
    }
  }
}
