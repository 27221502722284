@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-card-rich {
  &-header {
    display: flex;
    justify-items: center;
    flex-direction: column;
    padding: 1.5rem 1rem 1rem;
    color: var(--ac-color-primary);
    border-bottom: 1px solid var(--ac-color-neutral-divider);
    min-height: 3.25rem;
    box-sizing: content-box;

    @media #{$breakpoint-md-up} {
      padding: 2rem;
    }
  }

  &-container {
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding: 1rem;

    @media #{$breakpoint-md-up} {
      padding: 2rem;
    }
  }
}
