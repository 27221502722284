@import '../../../../styles/globals/typography';
@import '../../../../styles/globals/breakpoints';
@import '../../../../styles/globals/mixins';
@import '../../../../styles/globals/variables';
@import '../../../../styles/globals/grid';
@import '../../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-data-explorer-company-approach-tab {
  &-grid {
    &-container {
      display: grid;
      gap: 2rem;
    }

    &-section {
      display: grid;
      grid-template-columns: 1fr;
      gap: 2rem;

      &[data-item-length="1"] {
        @media #{$breakpoint-md-up} {
          grid-template-columns: 1fr;
        }
      }
      &[data-item-length="2"] {
        @media #{$breakpoint-md-up} {
          grid-template-columns: 1fr 1fr;
          gap: 2rem;
        }
      }
      &[data-item-length="3"] {
        @media #{$breakpoint-md-up} {
          grid-template-columns: 1fr 1fr 1fr;
          gap: 1.5rem;
        }
      }
    }
    &-title {
      margin-bottom: -2rem;
    }
  }

  &-section {
    &-subtitle {
      margin-top: 0.5rem;
      margin-bottom: 0.5rem;
    }
    &-title {
      margin-bottom: 0.5rem;
    }

    &-body {
      display: block;
      margin: 0.5rem 0;
    }
  }

  &-activity {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    border: 0;

    &-title {
      font-weight: 500;
      margin: 0 0 0.5rem 0;
      line-height: 1.25;
      color: var(--ac-color-neutral-black);
      font-size: 1.25rem;
    }

    &-item {
      border: 2px solid rgba(0, 0, 0, 0);
      box-sizing: border-box;
      font-weight: 500;
      padding: 0.5rem 0.75rem;
      background-color: var(--ac-color-neutral-white);
      display: inline-block;
      grid-template-columns: 1fr min-content;
      font-size: 1rem;
      line-height: 1.25;
      margin: 0;
      border-radius: 0.25rem;

      &-inner {
        display: grid;
        grid-template-columns: 1fr min-content;
        align-items: center;
        color: inherit;
      }

      &-empty {
        margin: 0;
      }

      &-label {
        color: var(--ac-color-primary);
        margin: 0;
      }

      &-icon {
        &-focus1 {
          color: var(--ac-color-secondary-green);
        }

        &-focus2 {
          color: var(--ac-color-secondary-orange);
        }
      }

      &-wrapper {
        display: block;
      }
    }
  }

  &-bottom {
    margin-top: 2.5rem;
    display: flex;
    flex-direction: column;
    gap: 0.5rem;
    @media #{$breakpoint-md-up} {
      flex-direction: row;
      gap: 2rem;
    }

    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      gap: 0.5rem;
    }
  }
}
