@import '../../../../styles/globals/typography';
@import '../../../../styles/globals/breakpoints';
@import '../../../../styles/globals/mixins';
@import '../../../../styles/globals/variables';
@import '../../../../styles/globals/grid';
@import '../../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-tab-label {
  box-sizing: content-box;
  min-height: 1.25rem;
  padding: 1.5rem 1rem;
  border: none;
  cursor: pointer;

  background-color: var(--ac-color-transparent);
  @include body-font();

  color: var(--ac-color-primary);
  font-weight: 500;
  font-size: var(--ac-typography-font-size-16);
  letter-spacing: 0.42px; // To prevent bold-shifting
  white-space: nowrap;
  max-width: 12.5rem;
  text-overflow: ellipsis;
  overflow: hidden;

  &--active {
    font-weight: 700;
    letter-spacing: unset;
  }

  &--error {
    color: var(--ac-color-feedback-error);
  }

  &:disabled:not(.ac-tab-label--active) {
    color: lightgray;
  }

  &:disabled {
    cursor: auto;
  }
}
