@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-tabs-header {
  position: relative;
  border-bottom: var(--ac-pixel-size-1) solid var(--ac-color-neutral-300);
  display: flex;
  width: 100%;
  flex: 1 1 auto;
  // overflow-x: auto;

  &--max-width {
    flex: 0 0 auto;
  }

  &--nav-visible {
    display: grid;
    grid-template-columns: max-content 1fr max-content;
    position: relative;

    //When active tab is not the first index
    &:not([data-tab-index='0']) {
      &:before {
        display: none;
        @media #{$breakpoint-md-up} {
          content: '';
          pointer-events: none;
          position: absolute;
          left: var(--ac-pixel-size-40);
          width: var(--ac-pixel-size-48);
          top: 0;
          bottom: 0;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 1) 0%,
            rgba(255, 255, 255, 0) 100%
          );
          z-index: 98;
        }
      }
    }

    //When active tab is not the last index
    &:not([data-tab-index='last']) {
      .ac-tabs-header-nav-right {
        position: relative;
        &:after {
          content: '';
          position: absolute;
          pointer-events: none;
          left: -var(--ac-pixel-size-40);
          width: var(--ac-pixel-size-40);
          top: 0;
          bottom: 0;
          background: linear-gradient(
            90deg,
            rgba(255, 255, 255, 0) 0%,
            rgba(255, 255, 255, 1) 90%
          );
          transform: translateX(-100%);
          z-index: 98;
        }
      }
    }
  }

  &-nav {
    &-button {
      display: none;
      @media #{$breakpoint-md-up} {
        all: unset;
        display: flex;
        align-items: center;
        justify-content: center;
        width: var(--ac-pixel-size-40);
        height: var(--ac-pixel-size-40);
        z-index: 99;
        position: relative;
        border-radius: 50%;
        background-color: var(--ac-color-neutral-white);
        box-shadow: var(--ac-box-shadow-button);

        &:hover {
          cursor: pointer;
        }

        &:disabled {
          cursor: auto;
          svg {
            fill: lightgray;
          }
        }
      }
    }

    &-left {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 0 0 0 0.5rem;
    }

    &-right {
      display: grid;
      grid-template-columns: max-content max-content;
      align-items: center;
      padding: 0 0.5rem 0 0;
      gap: 0.5rem;

      &-show-all {
        display: flex;
        justify-content: center;
        align-items: center;
        box-shadow: var(--ac-box-shadow-button);

        &:disabled {
          cursor: auto;
          color: lightgray;
          svg {
            fill: lightgray;
          }
        }

        &--mobile {
          all: unset;
          display: flex;
          align-items: center;
          justify-content: center;
          width: var(--ac-pixel-size-32);
          height: var(--ac-pixel-size-32);
          z-index: 99;
          position: relative;
          border-radius: 50%;
          background-color: var(--ac-color-neutral-white);
          box-shadow: var(--ac-box-shadow-button);
        }
      }
    }
  }

  &-inner {
    position: relative;
    overflow-x: auto;
    width: 100%;
    -ms-overflow-style: none; // IE and Edge
    scrollbar-width: none; // Firefox
    &::-webkit-scrollbar {
      display: none;
    }

    &-overlay {
      &-left {
        position: absolute;
        z-index: 99;
        left: 0;
        top: 0;
        bottom: 0;
        width: 48px;
        background: linear-gradient(
          90deg,
          rgba(255, 255, 255, 0) 0%,
          rgba(255, 255, 255, 1) 90%
        );
      }
    }

    &-elements {
      position: relative;
      left: 0;
      width: auto;
      white-space: nowrap;
    }
  }

  &__active-indicator {
    position: absolute;
    width: var(--ac-tab-label--width);
    margin: 0;
    z-index: 2;

    bottom: 0;

    border: solid var(--ac-color-primary);
    border-width: var(--ac-pixel-size-1) 0;
    transition: width 0.2s ease, transform 0.2s ease;

    &--error {
      border: solid var(--ac-color-feedback-error);
      border-width: var(--ac-pixel-size-1) 0;
    }
  }

  &-show-all {
    // position: absolute;
    width: 100%;
    display: block;

    max-width: 728px;
    max-height: 80vh;
    overflow-y: auto;

    &-button {
      all: unset;
      @include body-font();
      padding: 1rem;
      border-radius: 0.5rem;
      color: var(--ac-color-primary);
      border: 1px solid var(--ac-color-neutral-divider);
      transition: border-color 0.2s ease, background-color 0.2s ease,
        color 0.2s ease;
      font-weight: var(--ac-typography-font-weight-regular);
      text-align: left;

      &--active,
      &:hover {
        cursor: pointer;
        background-color: var(--ac-color-primary);
        color: var(--ac-color-neutral-white);
        border-color: var(--ac-color-primary);
      }
    }

    &-inner {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1rem;

      @media #{$breakpoint-md-up} {
        grid-template-columns: 1fr 1fr;
      }
    }
  }
}
