@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-layout-wrapper {
  min-height: 100vh;
  display: flex;
  flex-direction: column;

  &--white {
    background-color: var(--ac-color-neutral-white);
  }

  &-main {
    flex: 1 0 100%;
    box-sizing: border-box;
    padding: 2.815rem 0 8rem 0;
  }
}
