@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-hero {
  position: relative;
  padding-top: 0rem;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: calc(60vh - 60px);
  background: linear-gradient(359.07deg, #ffffff 10.81%, rgba(255, 255, 255, 0) 39.49%),
    linear-gradient(180deg, #ffffff 0%, rgba(255, 255, 255, 0) 26.83%),
    linear-gradient(245.95deg, rgba(246, 188, 236, 0.6) 4.7%, rgba(150, 200, 233, 0) 107.08%);

  &-wrapper {
    display: flex;
    flex-direction: column;
    max-width: $lg-max;
    width: 100%;
    padding: 2.5rem 1rem 2.5rem 1rem;

    @media #{$breakpoint-xl-up} {
      padding: 1rem;
      display: grid;
      gap: 1rem;
      grid-template-columns: 1fr 1fr;
    }
  }

  &-chart {
    &-wrapper {
      position: relative;
      width: 100%;
      overflow: hidden;
      padding: 0.5rem;

      @media #{$breakpoint-xl-up} {
        overflow: unset;
      }
    }
  }
}
