@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-pagination {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: var(--ac-pixel-size-20) 0;

  @media #{$breakpoint-md-up} {
    justify-content: flex-start;
    gap: 1.5rem;
  }

  &-number {
    all: unset;
    position: relative;
    box-sizing: border-box;
    width: 30px;
    height: 30px;
    display: inline-flex;
    align-items: center;
    justify-content: center;

    cursor: pointer;
    z-index: 1;
    transition: color 0.25s ease;
    text-align: center;
    border-radius: 50%;

    &:after {
      z-index: -1;
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      border-radius: 50%;
      background-color: var(--ac-color-secondary-lightblue3);
      transform: scale(0);
      transition: transform 0.25s ease;
    }

    &--active {
      background-color: #fff;
      border: 1px solid var(--ac-color-primary);
    }

    &:hover {
      color: var(--ac-color-primary);

      & > span {
        font-weight: var(--ac-typography-font-weight-bold);
      }

      &:after {
        transform: scale(1);
      }
    }
  }

  &-numbers {
    display: flex;
    gap: 1rem;
  }

  &-dots {
    color: var(--ac-color-primary);
    display: inline-block;
    width: 1.5rem;
    text-align: center;
  }

  &-prev-next-button {
    all: unset;
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 0.25rem;
    cursor: pointer;
    color: var(--ac-color-primary);
    fill: var(--ac-color-primary);
    padding: 0.5rem;

    &:disabled {
      cursor: default;
      color: use-alpha("--ac-color-primary", 0.5);
      fill: use-alpha("--ac-color-primary", 0.5);
    }
    p {
      display: none;
    }

    @media #{$breakpoint-md-up} {
      padding: 0;

      p {
        display: block;
      }
    }
  }
}
