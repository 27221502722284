@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-footer-daex {
  position: relative;

  a:hover {
    text-decoration: underline;
  }

  &-before {
    max-width: $lg-max;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    margin: 1.5rem auto;
    padding: 0 1rem;
    gap: 0.5rem 1.75rem;

    @media #{$breakpoint-lg-up} {
      flex-direction: row;
      align-items: center;
      padding: 0 0.75rem;
    }

    :first-child {
      @media #{$breakpoint-lg-up} {
        width: 100%;
        flex-grow: 1;
        padding: 0;
      }
    }

    & > * {
      display: flex;
      align-items: center;
      margin: 0;
      gap: 0.75rem;
    }

    i:first-of-type {
      margin: 1rem;
      position: relative;

      &::before {
        content: '';
        display: block;
        width: 2rem;
        height: 2rem;
        border-radius: 50%;
        background-color: var(--ac-color-secondary-lightblue3);
        position: absolute;
        left: 0;
        top: 0;
        transform: translate(-22%, -22%);
        z-index: 0;
      }
    }
  }

  &-info {
    background-color: var(--ac-color-secondary-lightblue3);

    &-container {
      max-width: $lg-max;
      display: flex;
      margin: 0 auto;
      padding: 1.5rem 1rem;
      flex-direction: column;

      @media #{$breakpoint-md-up} {
        flex-direction: row;
        justify-content: space-between;
        padding: 0 0.75rem;
      }

      .ac-footer-daex-info-logo {
        width: 6.75rem;
      }
    }

    &-content {
      display: flex;
      margin-top: 0.75rem;
      margin-bottom: 1rem;
      flex-direction: column;
      gap: 1rem;

      @media #{$breakpoint-md-up} {
        flex-direction: row;
        gap: 2.5rem;
      }

      &-left {
        a i {
          margin-left: 0.75rem;
        }
      }

      &-left,
      &-right {
        display: flex;
        flex-direction: column;
      }
    }
  }
  &-links {
    padding: 0 1rem;
    background-color: var(--ac-color-primary);

    &-container {
      max-width: $lg-max;
      margin: 0 auto;
      box-sizing: border-box;
      display: flex;
      flex-direction: column;
      gap: 0.5rem;
      padding: 1.25rem 0;

      @media #{$breakpoint-md-up} {
        flex-direction: row;
        gap: 2rem;
      }
    }

    a {
      color: var(--ac-color-neutral-white);
      text-overflow: ellipsis;
      white-space: nowrap;
      min-width: 0;
      overflow: hidden;
      padding: 0.25rem 0;

      &:hover {
        color: var(--ac-color-neutral-white);
      }
    }

    a i {
      margin-left: 0.75rem;
      fill: var(--ac-color-neutral-white);
    }
  }
}
