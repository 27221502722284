@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-search-input {
  margin: 3rem 1rem 2.5rem 0;
  box-shadow: var(--ac-box-shadow-md);
  position: relative;
  box-sizing: border-box;
  border-radius: 6rem;
  max-width: 31.25rem;

  button {
    position: absolute;
    top: 0;
    border-radius: 6rem;
    height: 100%;
    width: 8rem;
    right: -1rem;
  }
}
