@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-no-reporting {
  display: flex;
  flex-direction: column;
  justify-content: flex;
  align-items: center;
}
