@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-company-tabs {
  position: sticky;
  top: 0;
  left: 0;
  right: 0;
  background-color: #fff;
  border-bottom: 1px solid var(--ac-color-neutral-divider);
  z-index: 99;
  display: grid;
  grid-template-columns: max-content 1fr;
  align-items: center;
  min-height: 4.25rem;

  @media #{$breakpoint-md-up} {
    gap: 2.5rem;
    padding: 0 2.5rem;
  }

  &:before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    box-shadow: var(--ac-box-shadow-md-2);
    z-index: -1;
  }

  &-back-button {
    display: none;

    @media #{$breakpoint-md-up} {
      display: flex;
    }
  }

  &-inner {
    overflow: hidden;
  }
}
