@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-card {
  width: 100%;
  border-radius: var(--ac-pixel-size-21);
  background-color: var(--ac-color-neutral-white);
  box-sizing: border-box;

  &--padding-32 {
    padding: var(--ac-pixel-size-24) var(--ac-pixel-size-16);

    @media #{$breakpoint-md-up} {
      padding: var(--ac-pixel-size-32);
    }
  }
  &--elevation-md {
    box-shadow: var(--ac-box-shadow-md);
  }
}
