// Creds to https://github.com/drewbot/sass-flexbox-grid
// Creds to https://github.com/kristoferjoseph/flexboxgrid
// No gutters
// No text alignment with justified container classes (center-xs, for example)
@use "sass:math";

// Condition to run inside of the col mixin * not for developer use *
@mixin col-condition($col-number) {
  @if type-of($col-number) == number {
    @if $col-number > $grid-columns or $col-number == 0 {
      @warn "Column number must be greater than 0 and less than or equal to total number of columns in the grid (#{$grid-columns})";
    } @else {
      box-sizing: border-box;
      flex-basis: math.div(100%, $grid-columns * $col-number);
      max-width: math.div(100%, $grid-columns * $col-number);
      padding: $gutter;
    }
    // If no col number is passed then arg is set to "auto" by default
    // "auto" mode fills the horizontal space with evenly sized containers
  } @else if $col-number == "auto" {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: $gutter;
  } @else {
    @warn "Column number argument must either be the string 'auto' or a number greater than 0 and less than or equal to total number of columns in the grid (#{$grid-columns})";
  }
} // Condition to run inside of the col mixin * not for developer use *

@mixin col($breakpoint: "xs", $col-number: "auto") {
  // If no breakpoint is passed and a col number is just use the first arg as the col number and act like xs
  @if type-of($breakpoint) == number {
    $col-number: $breakpoint;
    @include col-condition($col-number);
  } @else if $breakpoint == "xs" {
    @include col-condition($col-number);
  } @else if $breakpoint == "sm" {
    @media #{$breakpoint-sm-up} {
      @include col-condition($col-number);
    }
  } @else if $breakpoint == "md" {
    @media #{$breakpoint-md-up} {
      @include col-condition($col-number);
    }
  } @else if $breakpoint == "lg" {
    @media #{$breakpoint-lg-up} {
      @include col-condition($col-number);
    }
  } @else if $breakpoint == "xl" {
    @media #{$breakpoint-xl-up} {
      @include col-condition($col-number);
    }
  } @else {
    @warn "col mixin requires one of the existing breakpoint prefixes (#{$breakpoint-up-prefixes})";
  }
}

// Mixin to run inside of for loop - creates col/breakpoint classes * Not for developer use *
@mixin col-factory($thisPrefix) {
  .col-#{$thisPrefix} {
    box-sizing: border-box;
    flex-grow: 1;
    flex-basis: 0;
    max-width: 100%;
    padding: $gutter;
  }
  @for $i from 1 through $grid-columns {
    .col-#{$thisPrefix}-#{$i} {
      box-sizing: border-box;
      flex-basis: math.div(100%, $grid-columns * $i);
      max-width: math.div(100%, $grid-columns * $i);
      padding: $gutter;
    }
    .col-#{$thisPrefix}-offset-#{$i} {
      margin-left: math.div(100%, $grid-columns * $i);
    }
  }
  .row.start-#{$thisPrefix} {
    justify-content: flex-start;
  }
  .row.center-#{$thisPrefix} {
    justify-content: center;
  }
  .row.end-#{$thisPrefix} {
    justify-content: flex-end;
  }
  .row.top-#{$thisPrefix} {
    align-items: flex-start;
  }
  .row.middle-#{$thisPrefix} {
    align-items: center;
  }
  .row.bottom-#{$thisPrefix} {
    align-items: flex-end;
  }
  .row.around-#{$thisPrefix} {
    justify-content: space-around;
  }
  .row.between-#{$thisPrefix} {
    justify-content: space-between;
  }
  .first-#{$thisPrefix} {
    order: -1;
  }
  .last-#{$thisPrefix} {
    order: 1;
  }
} // Mixin to run inside of for loop - creates col/breakpoint classes

@for $i from 1 through length($breakpoint-up-prefixes) {
  $thisPrefix: nth($breakpoint-up-prefixes, $i);
  @if $thisPrefix == "xs" {
    @include col-factory($thisPrefix);
  } @else if $thisPrefix == "sm" {
    @media #{$breakpoint-sm-up} {
      @include col-factory($thisPrefix);
    }
  } @else if $thisPrefix == "md" {
    @media #{$breakpoint-md-up} {
      @include col-factory($thisPrefix);
    }
  } @else if $thisPrefix == "lg" {
    @media #{$breakpoint-lg-up} {
      @include col-factory($thisPrefix);
    }
  } @else if $thisPrefix == "xl" {
    @media #{$breakpoint-xl-up} {
      @include col-factory($thisPrefix);
    }
  }
}

// .row {
//   box-sizing: border-box;
//   margin: 0 auto;
//   width: 100%;
//   display: -webkit-flex;
//   display: -ms-flexbox;
//   display: flex;
//   flex: 0 1 auto;
//   flex-direction: row;
//   flex-wrap: wrap;
// }

// .col {
//   @include col();
// }

// .col-gutter-lr {
//   padding: 0 $gutter;
// }

// .col-no-gutter {
//   padding: 0;
// }

// .row.reverse {
//   flex-direction: row-reverse;
// }

// .row.flex-column {
//   flex-direction: column;
// }

// .col.reverse {
//   flex-direction: column-reverse;
// }

// .first {
//   order: -1;
// }

// .last {
//   order: 1;
// }

// .align-start {
//   align-self: flex-start;
// }

// .align-end {
//   align-self: flex-end;
// }

// .align-center {
//   align-self: center;
// }

// .align-baseline {
//   align-self: baseline;
// }

// .align-stretch {
//   align-self: stretch;
// }
