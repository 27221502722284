@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-analytics-company-heading {
  &-meta {
    display: flex;
    flex: 1 0 100%;
    max-width: 62.5rem;
    gap: 1rem 2rem;
    margin-top: 1rem;
    color: var(--ac-color-primary);
    fill: var(--ac-color-primary);
    flex-direction: column;

    @media #{$breakpoint-md-up} {
      display: grid;
      grid-template-columns: repeat(2, 1fr);
      column-gap: 24px;
      row-gap: 8px;
    }

    span {
      display: flex;
      align-items: center;
    }

    i {
      margin-right: 0.625rem;
    }
  }
}
