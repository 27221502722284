@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-button-back {
  all: unset;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 0.25rem;
  color: use-alpha("--ac-color-primary", 0.5);
  fill: use-alpha("--ac-color-primary", 0.5);

  &:hover {
    cursor: pointer;
    color: var(--ac-color-primary);
    fill: var(--ac-color-primary);
  }
}
