@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-index-filter {
  background-color: #fff;
  border-radius: 0px 0px 2.5rem 2.5rem;
  position: -webkit-sticky;
  position: sticky;
  top: var(--ac-mobile-menu-height);
  z-index: 9;
  box-shadow:
    0px 4px 24px rgba(178, 183, 219, 0.25),
    0px 10px 28px rgba(178, 183, 219, 0.11);

  @media #{$breakpoint-md-up} {
    top: 0;
  }

  &-wrapper {
    max-width: $lg-max;
    width: 100%;
    margin: 0 auto;
    padding: 1rem 1rem 2.5rem;
  }

  &-selects {
    display: flex;
    gap: 1rem;
    padding-top: 1.5rem;
    flex-wrap: wrap;
  }

  &-action {
    display: flex;
    align-items: flex-end;
    padding: 0.5rem 0;
  }
}
