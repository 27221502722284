@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-card-circle-females {
  &-chart {
    width: 100%;
    display: flex;
    position: relative;
    height: 100%;
    flex: 1 0 auto;
    flex-direction: column;
    text-align: center;
    gap: 1.5rem;
    padding-top: 2rem;

    &-title {
      color: var(--color);
      margin-top: 1.5rem;

      &-tooltip {
        margin-left: 0.5rem;
      }
    }

    a {
      margin-right: 0.5rem;
    }

    &-component {
      position: absolute;
      width: inherit;
      height: inherit;

      &-wrapper {
        box-sizing: border-box;
        height: 164px;
      }
    }
  }
}
