@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-title {
  @include title-font();
  margin: 0;
  display: block;
  color: var(--ac-color-primary);

  &--1 {
    font-size: var(--ac-typography-font-size-32);
    font-weight: 900;
    line-height: var(--ac-typography-line-height-120);
  }

  &--2 {
    font-size: var(--ac-typography-font-size-26);
    font-weight: 900;
    line-height: var(--ac-typography-line-height-120);
  }

  &--3 {
    font-size: var(--ac-typography-font-size-18);
    font-weight: 900;
    line-height: var(--ac-typography-line-height-120);
  }
  &--4 {
    font-size: var(--ac-typography-font-size-16);
    font-weight: 900;
    line-height: var(--ac-typography-line-height-120);
  }
  @media #{$breakpoint-md-up} {
    &--1 {
      font-size: var(--ac-typography-font-size-48);
    }

    &--2 {
      font-size: var(--ac-typography-font-size-32);
    }

    &--3 {
      font-size: var(--ac-typography-font-size-20);
    }

    &--4 {
      font-size: var(--ac-typography-font-size-18);
    }
    &--4 {
      font-size: var(--ac-typography-font-size-18);
      line-height: var(--ac-typography-line-height-22);
    }
  }
}
