@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-tooltip {
  background-color: var(--ac-color-neutral-white);
  padding: var(--ac-pixel-size-20);
  border-radius: var(--ac-pixel-size-7);
  color: var(--ac-color-primary);
  box-sizing: border-box;
  z-index: 999;
  filter: drop-shadow(0px 4px 24px rgba(178, 183, 219, 0.55)) drop-shadow(0px 10px 28px rgba(102, 108, 149, 0.31));
  max-width: 16rem;

  &-arrow {
    position: absolute;
    z-index: -1;
  }

  &[data-popper-placement*="top"] {
    .ac-tooltip-arrow {
      bottom: -0.75rem;
      border-left: 0.75rem solid transparent;
      border-right: 0.75rem solid transparent;
      border-top: 0.75rem solid var(--ac-color-neutral-white);
      width: 0;
      height: 0;
    }
  }

  &[data-popper-placement*="bottom"] {
    .ac-tooltip-arrow {
      top: -0.75rem;
      border-left: 0.75rem solid transparent;
      border-right: 0.75rem solid transparent;
      border-bottom: 0.75rem solid var(--ac-color-neutral-white);
      width: 0;
      height: 0;
    }
  }

  &[data-popper-placement*="left"] {
    .ac-tooltip-arrow {
      right: -0.75rem;
      border-left: 0.75rem solid var(--ac-color-neutral-white);
      border-top: 0.75rem solid transparent;
      border-bottom: 0.75rem solid transparent;
      width: 0;
      height: 0;
    }
  }

  &[data-popper-placement*="right"] {
    .ac-tooltip-arrow {
      left: -0.75rem;
      border-right: 0.75rem solid var(--ac-color-neutral-white);
      border-top: 0.75rem solid transparent;
      border-bottom: 0.75rem solid transparent;
      width: 0;
      height: 0;
    }
  }
}
