@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-card-bar-target {
  opacity: 0;
  max-height: 0;
  transition: all 0.25s ease;
  margin: -0.75rem 0;

  &--visible {
    opacity: 1;
    max-height: 65rem;
    margin: unset;
  }

  &-legend {
    grid-column: span 2;
    color: var(--ac-clor-primary);

    @media #{$breakpoint-md-up} {
      margin-left: auto;
    }

    & > div {
      display: flex;
      gap: 1rem 3.75rem;
      flex-wrap: wrap;

      span {
        margin-left: 19px;
        font-size: var(--ac-typography-font-size-14);
        color: var(--ac-color-neutral-black);
        position: relative;

        &::after {
          content: '';
          display: block;
          width: 0.75rem;
          height: 0.75rem;
          background-color: var(--fill);
          position: absolute;
          border-radius: 50%;
          // Half of the circle + 7px margin as per design
          left: -19px;
          // Half of the circle
          top: 6px;
        }
      }
    }
  }
  &-charts {
    width: 100%;
    display: flex;
    gap: 2rem;
    flex-direction: column;
    text-align: left;
    padding-top: 1.5rem;
    grid-column: span 2;

    .ac-card-bar-target-chart-row {
      @media #{$breakpoint-md-up} {
        grid-template-columns: min(7.5rem, 12.5%) min(7.5rem, 12.5%) minmax(
            50%,
            75%
          ) !important;
        gap: 5rem;
      }
    }
  }

  &-chart-row {
    display: flex;
    flex-direction: column;
    width: 100%;

    @media #{$breakpoint-md-up} {
      display: grid;
      grid-template-columns: 1fr;
      gap: 1.5rem;
    }

    h2 {
      height: 2.625rem;
    }

    p {
      margin: 0.5rem 0;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    h2,
    p {
      color: var(--ac-color-neutral-header);
    }
  }

  &-chart-hint {
    overflow: hidden;
  }

  &-with-icon {
    display: flex;
    align-items: flex-start;
    gap: 12px;
  }
}
