@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-data-item {
  display: inline-block;
  padding: 2rem 0;

  @media #{$breakpoint-md-up} {
    padding: 2rem 1rem;
  }

  &--vertical {
    text-align: center;
    flex-direction: column;
  }

  &--horizontal {
    text-align: initial;
    flex-direction: row;
  }

  &-inner {
    display: flex;
    flex-direction: inherit;
    align-items: center;
    gap: 1rem;

    @media #{$breakpoint-xl-up} {
      &--center {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
    }

    &-icon {
      fill: inherit;
    }

    &-heading {
      display: flex;
      text-align: center;
    }

    &-title {
      color: var(--ac-color-neutral-black);
    }

    &-subtitle {
      color: var(--ac-color-neutral-header);
      display: flex;
      text-align: center;
    }

    &-label {
      display: flex;
      text-align: center;
    }

    &-info {
      margin-left: 0.5rem;
      fill: var(--ac-color-primary);
    }
  }
}
