@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-select {
  &-label {
    margin-bottom: 0.25rem;
  }
  select {
    border-radius: 1.25rem;
    background: var(--ac-color-neutral-white);
    border: 1px solid rgba(0, 0, 0, 0.16);
    padding: 0.375rem 1rem;
    min-width: 14rem;
    font-size: var(--ac-typography-font-size-16);
    font-family: inherit;
    appearance: none;
    background: transparent;
    background-image: url('~@assets/icons/chevron-down.svg');
    background-repeat: no-repeat;
    background-size: 1.5rem;
    background-position: right 0.75rem top 50%;

    cursor: pointer;
  }
}
