@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-data-block {
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;

  @media #{$breakpoint-xl-up} {
    text-align: center;
  }

  &-header {
    box-sizing: border-box;
    height: 86px;
    overflow: visible;
    padding: 2rem 0;

    @media #{$breakpoint-md-up} {
      padding: 2rem;
    }

    &-title {
      display: block;
      color: var(--color);
    }
  }

  &-items {
    &-inner {
      flex: 1 1 auto;
      display: flex;
      flex-direction: column;
    }
  }
}
