@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-typography {
  @include body-font;

  &--primary {
    color: var(--ac-color-primary);
  }

  &--lg {
    font-size: var(--ac-typography-font-size-20);
    line-height: 1.4;
  }

  &--md {
    font-size: var(--ac-typography-font-size-16);
    line-height: var(--ac-typography-line-height-24);
  }

  &--sm {
    font-size: var(--ac-typography-font-size-14);
    line-height: var(--ac-typography-line-height-20);
  }

  &--xs {
    font-size: var(--ac-typography-font-size-12);
    line-height: var(--ac-typography-line-height-20);
  }

  &--semibold {
    font-weight: var(--ac-typography-font-weight-bold);
  }

  &--regular {
    font-weight: var(--ac-typography-font-weight-regular);
  }

  &--light {
    font-weight: var(--ac-typography-font-weight-light);
  }
}
