.echarts-for-react {
  .tooltip {
    thead {
      display: flex;
      justify-content: flex-start;
    }
    tbody .table-grid {
      display: grid;
      grid-template-columns: 125px 1fr 1fr;
      gap: 24px;

      td:not(.has-data) {
        display: none;
      }
    }
  }
}
