@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-card-with-title {
  &-header {
    display: flex;
    align-items: center;
    fill: var(--ac-color-primary);
  }

  &-title {
    &-wrapper {
      padding-bottom: 24px;
    }

    &-sub {
      color: var(--ac-color-primary);
    }
  }

  &-icon {
    color: var(--ac-color-primary);
  }

  &-content {
    display: grid;
    grid-template-columns: 1fr;

    @media #{$breakpoint-lg-up} {
      &[data-grid-count='4'] {
        grid-template-columns: repeat(4, 1fr);
      }
      &[data-grid-count='3'] {
        grid-template-columns: repeat(3, 1fr);
      }
      &[data-grid-count='2'] {
        grid-template-columns: repeat(2, 1fr);
      }
    }
  }
}
