@font-face {
  font-family: 'Dax';
  font-display: swap;
  font-style: normal;
  font-weight: 400;
  src: url('~@assets/fonts/DaxOT/DaxOT-Regular.woff2') format('woff2'),
    url('~@assets/fonts/DaxOT/DaxOT-Regular.woff') format('woff');
}

@font-face {
  font-family: 'Dax';
  font-display: swap;
  font-style: normal;
  font-weight: 300;
  src: url('~@assets/fonts/DaxOT/DaxOT-Light.woff2') format('woff2'),
    url('~@assets/fonts/DaxOT/DaxOT-Light.woff') format('woff');
}

@font-face {
  font-family: 'Dax';
  font-display: swap;
  font-style: normal;
  font-weight: 500;
  src: url('~@assets/fonts/DaxOT/DaxOT-Medium.woff2') format('woff2'),
    url('~@assets/fonts/DaxOT/DaxOT-Medium.woff') format('woff');
}

@font-face {
  font-family: 'Dax';
  font-display: swap;
  font-style: normal;
  font-weight: 600;
  src: url('~@assets/fonts/DaxOT/DaxOT-Bold.woff2') format('woff2'),
    url('~@assets/fonts/DaxOT/DaxOT-Bold.woff') format('woff');
}

@font-face {
  font-family: 'Dax Black';
  font-display: swap;
  font-style: normal;
  font-weight: 900;
  src: url('~@assets/fonts/DaxOT/DaxOT-Black.woff2') format('woff2'),
    url('~@assets/fonts/DaxOT/DaxOT-Black.woff') format('woff');
}
