@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-text-input {
  padding: 0;
  line-height: var(--ac-typography-line-height-20);
  width: 100%;
  box-sizing: content-box;

  border: none;
  outline: none;
  background: none;

  transition: padding-left ease 0.2s, font-weight ease 0.2s;
  font-size: var(--ac-typography-font-size-14);
  color: var(--ac-color-neutral-900);

  &::placeholder {
    color: var(--ac-color-primary);
  }

  @media #{$breakpoint-md-up} {
    font-size: var(--ac-typography-font-size-16);
  }

  @include body-font();
}
