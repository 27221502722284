@import '../../../styles/globals/typography';
@import '../../../styles/globals/breakpoints';
@import '../../../styles/globals/mixins';
@import '../../../styles/globals/variables';
@import '../../../styles/globals/grid';
@import '../../../styles/components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

.ac-card-line-females {
  opacity: 0;
  max-height: 0;
  transition: all 0.25s ease;
  margin: -0.75rem 0;

  &--visible {
    opacity: 1;
    max-height: 50rem;
    margin: unset;
  }

  &-chart {
    position: absolute;
    width: 100%;
    height: inherit;

    &-wrapper {
      position: relative;
      width: 100%;
      height: 420px;
      overflow: hidden;
    }
  }
}
