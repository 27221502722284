@import 'globals/typography';
@import 'globals/breakpoints';
@import 'globals/mixins';
@import 'globals/variables';
@import 'globals/grid';
@import 'components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

@import './globals/typography';
@import './globals/breakpoints';
@import './globals/mixins';
@import './globals/variables';
@import './globals/grid';
@import './components/echarts-for-react.styles';

html > body {
  position: relative;
  background-color: var(--ac-color-neutral-background);
}

* {
  font-size: 16px;
  font-family: 'Dax';
}

a {
  text-decoration: none;
  color: var(--ac-color-neutral-black);
}

*,
*::before,
*::after {
  box-sizing: inherit;
}
